import jwt from 'jwt-decode'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { publicRoutes } from '~/AppRoutes'
import queryClient from '~/data/queryClient'
import { useGetUserPermissions } from '~/data/useData'
import { useAppDispatch, useAppSelector } from '~/store'
import {
  changeIdentity,
  changePermissions,
  changeToken,
  changeUser,
  logoutUser,
} from '~/store/slices/authSlice'
import { IIdentity, IUser, JWTDecoded } from '~/utils/types'

export const useAuth = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const { token, user, identity, permissions } = useAppSelector((store) => store.auth)
  const isLogged = Boolean(token)
  const [isPermissionsLoaded, setIsPermissionsLoaded] = useState(false)

  const lastLogin = useMemo(() => {
    if (user?.last_login) {
      return new Date(user.last_login)
    }
    return null
  }, [user])

  const userId = user?._id ?? user?.userId
  const profile = user?.profile ?? 'guest'

  const isAssigned = useCallback(
    (permission: string): boolean => {
      if (!permission) return true
      if (!permissions) return false
      return permissions.some((permissionElement) => {
        if (permission === permissionElement) return true
        const permissionWithPrefix = `permission:${permission}`
        return permissionWithPrefix === permissionElement
      })
    },
    [permissions],
  )

  const updateToken = useCallback(
    (newToken: string) => {
      dispatch(changeToken(newToken))
    },
    [dispatch],
  )

  const updateUser = useCallback(
    (newUser: IUser) => {
      dispatch(changeUser(newUser))
    },
    [dispatch],
  )

  const updateIdentity = useCallback(
    (newIdentity: IIdentity) => {
      dispatch(changeIdentity(newIdentity))
    },
    [dispatch],
  )

  const updatePermissions = useCallback(
    (permissions: string[]) => {
      dispatch(changePermissions(permissions))
    },
    [dispatch],
  )

  const handleLogout = useCallback(() => {
    queryClient.clear()
    dispatch(logoutUser())
    navigate('/auth/login')
  }, [dispatch, navigate])

  const userPermissions = useGetUserPermissions(user)

  useEffect(() => {
    if (userPermissions?.isSuccess) {
      updatePermissions(userPermissions?.data || [])
      setIsPermissionsLoaded(true)
    }
  }, [updatePermissions, userPermissions.data, userPermissions.isSuccess])

  useEffect(() => {
    if (publicRoutes.some((route) => pathname.startsWith(route))) return

    if (!token) {
      if (pathname !== '/auth/login') {
        navigate('/auth/login', {
          state: {
            redirectUrl: pathname,
          },
        })
      }

      return
    }

    const decodedToken: JWTDecoded<IUser> = jwt(token)
    if (decodedToken.exp === -1) return

    const expiryTime = new Date(decodedToken.exp * 1000)
    const currentTime = new Date()
    if (expiryTime < currentTime) {
      handleLogout()
      toast.error('Your session has expired, please login again.', {
        toastId: 'logout-toast',
      })
    }
  }, [token, handleLogout, pathname, navigate, search])

  return {
    token,
    user,
    identity,
    userId,
    isAssigned,
    profile,
    isLogged,
    lastLogin,
    updateToken,
    updateUser,
    updateIdentity,
    handleLogout,
    isPermissionsLoaded,
  }
}
