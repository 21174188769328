import { LogLevel } from '@azure/msal-browser'
import { MS_AUTHORITY, MS_CLIENT_ID } from '~/utils/consts'

export const msalConfig = {
  auth: {
    clientId: MS_CLIENT_ID,
    authority: MS_AUTHORITY,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
          default:
            console.log(message)
            return
        }
      },
    },
  },
}

export const loginRequest = {
  scopes: ['openid', 'profile', 'https://graph.microsoft.com/.default'],
}

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
}
