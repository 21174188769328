import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IIdentity, IUser } from '~/utils/types'

export interface AuthState {
  token: string | null
  user: IUser | null
  permissions: string[] | null
  identity: IIdentity | null
}

const initialState: AuthState = {
  token: null,
  user: null,
  identity: null,
  permissions: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeToken: (state: AuthState, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    changeUser: (state: AuthState, action: PayloadAction<IUser>) => {
      state.user = action.payload
    },
    changeIdentity: (state: AuthState, action: PayloadAction<IIdentity>) => {
      state.identity = action.payload
    },
    changePermissions(state, action: PayloadAction<string[]>) {
      state.permissions = action.payload
    },
    logoutUser: (state: AuthState) => {
      state.token = null
      state.user = null
      state.permissions = null
      state.identity = null
    },
  },
})

export const { changeToken, changeUser, changeIdentity, changePermissions, logoutUser } =
  authSlice.actions

export default authSlice.reducer
