import { capitalize } from 'lodash'
import { ReactNode } from 'react'
import { datatypeOptions } from '~/modules/inspection_item/components/InspectionItemForm'
import { IUploadReportTemplateForm } from '~/modules/report_template/pages/ReportTemplateTableScreen/ReportTemplateTableScreen'

export type NoReturn = void | Promise<void>

export interface IAppRoute {
  path: string
  element: ReactNode
}

export interface IPaginationParams {
  limit: number
  offset: number
}

export interface IPaginatedMetadataResponse extends IPaginationParams {
  total: number
}

export interface IPaginated<T> {
  docs: T[]
  metadata: IPaginatedMetadataResponse
}

export interface IResourceSearchParams {
  pagination?: IPaginationParams
  query?: string
}

/**
 * @deprecated
 * Use userProfiles array instead.
 */
export const oldUserProfiles = ['Adm', 'Inspector', 'Customer'] as const
export type TOldUserProfile = typeof oldUserProfiles[number]

export const userProfiles = ['manager', 'supervisor', 'inspector', 'guest'] as const
export type TUserProfile = typeof userProfiles[number]

export type TModalStates = 'create' | 'edit' | 'duplicate'

export interface IIdentity {
  _id?: string
  name: string
  email: string
  password_update_required?: boolean
}
export interface IUser {
  _id?: string
  identity?: IIdentity | string | null
  userId?: string
  name: string
  phone_number: string
  cellphone_number: string
  profile: TUserProfile | TOldUserProfile
  company?: ICompany | string
  newPassword?: string
  /**
   * @deprecated
   * Use permissions instead.
   */
  role?: 'unlimited' | 'operator_adm' | 'limited'
  travel_docs?: {
    passport_number?: string
    passport_issue_date?: string | Date
    passport_expiration_date?: string | Date
    passport_image_key?: string
    americanvisa_expiration_date?: string | Date
    americanvisa_image_key?: string
  }
  address?: IAddress
  bank_data?: {
    name?: string
    code?: string
    branch?: string
    account?: string
    pix_key?: string
    cpf?: string
    swift_code?: string
    iban?: string
    aba?: string
    zeller_code?: string
    branch_address?: IAddress
  }
  status: boolean | string
  last_login?: string
}

export interface IUserFilterParams {
  pagination: IPaginationParams
  name?: string
}

export type TProfileOption = {
  label: string
  value: TUserProfile | TOldUserProfile
  unassignable?: boolean
}

export type TStatusOption = {
  label: string
  value: string
  unassignable?: boolean
}

export const profileOptions: TProfileOption[] = [
  // { label: 'Admin', value: 'Adm' },
  // { label: 'Inspector', value: 'Inspector' },
  // { label: 'Customer', value: 'Customer' },
  { label: 'Manager', value: 'manager' },
  { label: 'Supervisor', value: 'supervisor' },
  { label: 'Inspector', value: 'inspector' },
  { label: 'Guest', value: 'guest' },
]

export const statusOptions: TStatusOption[] = [
  { label: 'Active', value: 'true' },
  { label: 'Deactivated', value: 'false' },
]

export interface IAddress {
  city?: string
  country?: string
  number?: string
  street?: string
  zip_code?: string
  other?: string
}

export interface IContactInfo {
  tech_cellphone?: string
  tech_email?: string
  tech_focal?: string
  tech_function?: string
  tech_phone?: string
}

export interface IInvoiceInfo {
  fin_email?: string
  fin_focal?: string
  fin_phone?: string
  invoice_to_address: [IAddress?]
}

export interface IInspectionContactInfo {
  name?: string
  email: string
  phone: string
  cellphone: string
  function: string
}

/**
 * @deprecated
 * Use TCompanyType instead
 *
 * Old Company Types, stills here for backwards compatibility
 */
export const oldCompanyTypes = ['client', 'provider', 'air_operator'] as const
export type TOldCompanyType = typeof oldCompanyTypes[number]

export const companyTypes = ['asset_manager', 'inspection_company', 'operator', 'customer'] as const
export type TCompanyType = typeof companyTypes[number]

interface ICompanyTypeOption {
  name: string
  label: string

  requiredPermission?: string
}

export const companyTypeOptions: ICompanyTypeOption[] = [
  {
    name: 'asset_manager',
    label: 'Asset Manager',
    requiredPermission: 'create_asset_manager',
  },
  {
    name: 'inspection_company',
    label: 'Audit Company',
    requiredPermission: 'create_inspection_company',
  },
  {
    name: 'operator',
    label: 'Operator',
    requiredPermission: 'create_operator',
  },
  {
    name: 'customer',
    label: 'Customer',
    requiredPermission: 'create_customer',
  },
]

export interface ICompany {
  _id?: string
  name: string
  aliases: string[]
  initials: string
  company_type: (TCompanyType | TOldCompanyType)[]
  contact_info: IContactInfo[]
  invoice_info: IInvoiceInfo[]
  inspection_contact_info: IInspectionContactInfo[]
  master: boolean
  parent?: string
  parent_id?: string
  photo?: File | null
  photo_url?: string
  photo_key?: string
}

export interface IAircraftManufacturer {
  _id?: string
  name: string
  shortname: string
  aliases: string[]
}

export interface IAssetType {
  _id?: string
  name: string
}

export const initialAssetType = (): IAssetType => ({
  name: '',
})

export interface IAircraftModel {
  _id?: string
  owner?: ICompany
  resource_scope: TResourceScope
  name: string
  alias: string[]
  asset_type?: IAssetType
  manufacturer?: IAircraftManufacturer
}

export const initialAircraftModel = (): IAircraftModel => ({
  name: '',
  alias: [],
  resource_scope: 'company',
})

export interface IAircraft {
  _id?: string
  owner?: string | ICompany
  serial_number: string
  manufacture_date?: Date | string
  aircraft_model?: IAircraftModel | null
  register?: string
  audits: IInspection[]
  id_external_system?: string
  active?: boolean
}

export interface IAuditedAsset extends IAircraft {
  register?: string
}

export const initialAircraft = (): IAircraft => ({
  serial_number: '',
  manufacture_date: '',
  id_external_system: '',
  aircraft_model: null,
  register: '',
  audits: [],
})

export interface IInspectionType {
  _id?: string
  name: string
}

export const initialInspectionType = (): IInspectionType => ({
  name: '',
})

export const initialCompany = (): ICompany => ({
  name: '',
  aliases: [],
  initials: '',
  company_type: [],
  contact_info: [],
  invoice_info: [],
  inspection_contact_info: [],
  master: false,
  photo: null,
})

export const initialAddress = (): IAddress => ({
  city: '',
  country: '',
  number: '',
  other: '',
  street: '',
  zip_code: '',
})

export const initialUser = (overrideValues?: Partial<IUser>): IUser => ({
  name: '',
  profile: 'guest',
  identity: null,
  company: '',
  phone_number: '',
  cellphone_number: '+1',
  role: 'limited',
  address: initialAddress(),
  travel_docs: {
    passport_image_key: '',
    americanvisa_image_key: '',
    passport_number: '',
    passport_issue_date: '',
    passport_expiration_date: '',
    americanvisa_expiration_date: '',
  },
  status: true,
  bank_data: {
    aba: '',
    account: '',
    branch: '',
    branch_address: initialAddress(),
    code: '',
    cpf: '',
    iban: '',
    name: '',
    pix_key: '',
    swift_code: '',
    zeller_code: '',
  },
  ...(overrideValues ?? {}),
})

export const variableDatatypes = [
  'string',
  'date',
  'year',
  'email',
  'number',
  'decimal',
  'yes/no',
] as const
export type TVariableDatatype = typeof variableDatatypes[number]

export const getDatatypeLabel = (value: TVariableDatatype): string => {
  if (value === 'yes/no') return 'Yes/No'
  return capitalize(value)
}

export type TChecklistItem = 'AIR' | 'INS' | 'AUD' | 'OPS' | 'WB' | 'COM' | 'ENG' | 'REC'

export type TChecklistItemOption = {
  label: string
  value: TChecklistItem
  sortOrder: number
}

export const checklistItemOptions: TChecklistItemOption[] = [
  {
    label: 'Asset Info',
    value: 'AIR',
    sortOrder: 0,
  },
  {
    label: 'Inspector Info',
    value: 'INS',
    sortOrder: 1,
  },
  {
    label: 'Audit Info',
    value: 'AUD',
    sortOrder: 2,
  },
  {
    label: 'Operations Info',
    value: 'OPS',
    sortOrder: 3,
  },
  {
    label: 'Weight and Balance Info',
    value: 'WB',
    sortOrder: 4,
  },
  {
    label: 'Component/Audit Listing Map',
    value: 'COM',
    sortOrder: 5,
  },
  {
    label: 'Engine Info',
    value: 'ENG',
    sortOrder: 6,
  },
  {
    label: 'Record Info',
    value: 'REC',
    sortOrder: 7,
  },
]
export interface IInspectionItem {
  _id?: string
  description: string
  variable_name: string
  variable_datatype: TVariableDatatype
  basic_item: boolean
  source: string
  required_doc: string
  required_doc_name: string
  checklist_item: TChecklistItem
}

export interface IInspectionItemReferences {
  _id: string
  description: string
}

export const initialInspectionItem = (): IInspectionItem => ({
  description: '',
  variable_name: '',
  variable_datatype: 'string',
  basic_item: false,
  source: '',
  required_doc_name: '',
  required_doc: '',
  checklist_item: 'AIR',
})

export interface IInspectionScope {
  _id?: string
  name: string
}

export const initialInspectionScope = (): IInspectionScope => ({
  name: '',
})

export const ComponentTypes = [
  'avionics',
  'basic components',
  'blades',
  'engine',
  'gearboxes',
  'general equipment',
  'interior components',
  'landing gears',
  'major components',
  'role/mission equipment',
] as const

export const componentTypes = [...ComponentTypes]

export type TComponentType = typeof componentTypes[number]

interface IComponentTypeLabel {
  value: TComponentType
  label: string
}

export const componentTypeLabels: IComponentTypeLabel[] = [
  { value: 'avionics', label: 'Avionics' },
  { value: 'basic components', label: 'Engine Components' },
  { value: 'blades', label: 'Main Rotor Blades' },
  { value: 'engine', label: 'Engine' },
  { value: 'gearboxes', label: 'Gearboxes' },
  { value: 'general equipment', label: 'General Equipment' },
  { value: 'interior components', label: 'Tail Rotor Blades' },
  { value: 'landing gears', label: 'Landing Gears' },
  { value: 'major components', label: 'Major Components' },
  { value: 'role/mission equipment', label: 'Role/Mission Equipment' },
]

export function getComponentTypeLabel(componentType: TComponentType) {
  return componentTypeLabels.find((i) => i.value === componentType)?.label || '-'
}

export const intervalTypes = ['oc', 'cycles', 'hours', 'days', 'months', 'years']

export type TIntervalType = typeof intervalTypes[number]

export const intervalClasses = ['oc', 'ovhl', 'sll']

export type TIntervalClass = typeof intervalClasses[number]

export interface IComponent {
  _id?: string
  name: string
  aliases: string[]
  source: string
  component_type: TComponentType
  part_number: string[]
  aircraft_model?: IAircraftModel | string
  interval: number
  interval_type: TIntervalType
  interval_class: TIntervalClass
}

export const physicalItemDatatypes = ['string', 'number', 's/ns/na', 'g/o/b']
export type TPhysicalItemDatatype = typeof physicalItemDatatypes[number]
export const physicalItemDatatypesNameMaps: Record<TPhysicalItemDatatype, string> = {
  's/ns/na': 'S/NS/NA',
  string: 'Text',
  number: 'Number',
  'g/o/b': 'GOOD/OKAY/BAD', // Good, Okay or Bad
}

export interface IPhysicalItem {
  _id?: string
  description: string
  variable_name: string
  variable_datatype: TPhysicalItemDatatype
}

export const generateMockPhysicalItem = (): IPhysicalItem => ({
  description: '',
  variable_name: '',
  variable_datatype: 's/ns/na',
})

export interface IPhysicalItemCategory<PhysicalItemType = IPhysicalItem> {
  name: string
  physical_items: PhysicalItemType[]
}

export const initialComponent = (): IComponent => ({
  name: '',
  aliases: [],
  source: '',
  component_type: 'engine',
  part_number: [],
  interval: 0,
  interval_type: 'oc',
  interval_class: 'oc',
})

export interface IComponentParameter {
  _id?: string
  parameter?: string
  variable_name?: string
  variable_datatype?: TVariableDatatype
}

export const initialComponentParameter = (): IComponentParameter => ({
  parameter: '',
  variable_name: '',
  variable_datatype: 'string',
})

export interface IComponentParameterGroup {
  component_type?: TComponentType
  component_parameters?: IComponentParameter[]
}

export const initialPhotoCategory = (): IPhotoCategory => ({
  name: '',
  main_photo: true,
  data_plate: false,
  part_one: false,
  additional_images: false,
  findings: true,
  uuid: '',
  description: '',
  what_to_look_for: '',
})

export interface IValuationItemsCategory {
  name: string
  valuation_items: IValuationItem[]
}

export const initialValuationItemsCategory: IValuationItemsCategory = {
  name: '',
  valuation_items: [],
}

export interface IChecklistTransaction {
  operation: string
  key: string
}

export interface IChecklistRevision {
  transactions: IChecklistTransaction[]
  author: IUser
  date: Date
}

export interface IRecordCategory {
  uuid: string
  name: string
  description?: string
  allowed_file_classes: IFileClass[]
}

export const initialRecordCategory = (): IRecordCategory => ({
  uuid: crypto.randomUUID(),
  name: '',
  description: '',
  allowed_file_classes: [],
})

export interface IInspectionChecklist {
  _id?: string
  owner?: ICompany | string
  aircraft_model?: IAircraftModel | string
  customer?: ICompany | string
  component_items: IComponent[]
  component_parameter_groups?: IComponentParameterGroup[]
  description: string
  inspection_items: IInspectionItem[]
  valuation_items_categories: IValuationItemsCategory[]
  inspection_scope?: IInspectionScope | string
  inspection_type?: IInspectionType | string
  physical_items_categories?: IPhysicalItemCategory<IPhysicalItem>[]
  photo_categories?: IPhotoCategory[]
  discrepancy_categories?: IDiscrepancyCategory[]
  document_categories?: string[]
  record_categories?: IRecordCategory[]
  tracking_items: {
    ad: boolean
    sb: boolean
    stc: boolean
    repair_info: boolean
    incidents_or_accidents: boolean
    component_changes: boolean
  }
  revisions: IChecklistRevision[]
  updated_by?: IUser
  created_at?: Date
  updated_at?: Date
  report_templates?: IReportTemplate[]
  photo?: File | null
  photo_url?: string
  photo_key?: string
}

export interface ILeanInspectionChecklist {
  _id: string
  aircraft_model: IAircraftModel
  company: ICompany
  customer: ICompany
  component_items: string[]
  description: string
  inspection_items: string[]
  inspection_scope: IInspectionScope
  inspection_type: IInspectionType
  physical_items_categories?: IPhysicalItemCategory<IPhysicalItem>[]
  tracking_items: {
    ad: boolean
    sb: boolean
    stc: boolean
    repair_info: boolean
    incidents_or_accidents: boolean
    component_changes: boolean
  }
}

export type ICreateInspectionChecklist = IInspectionChecklist & {
  physical_items_categories?: IPhysicalItemCategory<string>[]
}

export const initialInspectionChecklist = (): IInspectionChecklist => ({
  aircraft_model: '',
  customer: '',
  owner: '',
  component_items: [],
  component_parameter_groups: [],
  description: '',
  inspection_items: [],
  valuation_items_categories: [],
  inspection_scope: '',
  inspection_type: '',
  tracking_items: {
    ad: false,
    sb: false,
    stc: false,
    repair_info: false,
    incidents_or_accidents: false,
    component_changes: false,
  },
  revisions: [],
  record_categories: [],
  photo: null,
  photo_url: '',
})

export type TSubmission = { [index: string]: string | Date | number; created_at: Date; id: string }

export type TInspectionStatus =
  | 'booked'
  | 'in_progress'
  | 'report_ready'
  | 'waiting_finding_review'
  | 'closed'
  | 'cancelled'

interface IInspectionStatusOption {
  value: TInspectionStatus
  label: string
}

export const inspectionStatusOptions: IInspectionStatusOption[] = [
  { value: 'booked', label: 'Booked' },
  { value: 'in_progress', label: 'In Progress' },
  { value: 'report_ready', label: 'Report Ready' },
  { value: 'waiting_finding_review', label: 'Waiting Finding Review' },
  { value: 'closed', label: 'Closed' },
  { value: 'cancelled', label: 'Cancelled' },
]

export const getInspectionStatusLabel = (status: TInspectionStatus) =>
  inspectionStatusOptions.find((i) => i.value === status)?.label ?? ''

export interface IOldInspection {
  _id?: string
  aircraft?: IAircraft | string
  aircraft_register?: string
  checklist?: IInspectionChecklist | string
  checklist_ref?: string
  component_items?: IComponent[]
  country?: string
  customer?: ICompany | string
  due_date?: Date | null
  inspection_items?: IInspectionItem[]
  inspection_number?: string
  inspector?: IUser | string
  operator?: ICompany | string
  po_item?: string
  po_number?: string
  project?: string
  region?: string
  contact_info?: IContactInfo
  yom?: Date | null
  status?: TInspectionStatus
  submission_values?: {
    [index: string]: string | Date | number
  }
  submissions?: TSubmission[]
}

export interface IAircraftResponse {
  _id?: string
  serial_number: string
  manufacture_date?: Date
  aircraft_model?: IAircraftModel
}

export interface IInspectionResponse {
  _id?: string
  aircraft?: IAircraftResponse
  aircraft_register?: string
  checklist?: IInspectionChecklist
  checklist_ref?: string
  component_items?: IComponent[]
  country?: string
  customer?: ICompany
  due_date?: Date | null
  inspection_items?: IInspectionItem[]
  inspection_number?: string
  inspector?: IUser
  operator?: ICompany
  contact_info?: IContactInfo
  po_item?: string
  po_number?: string
  project?: string
  region?: string
  yom?: Date | null
  submission_values?: {
    [index: string]: string | Date | number
  }
  submissions?: TSubmission[]
}

export interface IGeneralInfo {
  [key: string]:
    | ICompany
    | IInspectionChecklist
    | string
    | IContactInfo
    | IUser
    | IUser[]
    | string[]
    | Date
    | undefined
  inspection_company: ICompany
  checklist?: IInspectionChecklist
  checklist_ref?: string
  customer?: ICompany
  contact_info?: IContactInfo
  operator?: ICompany
  inspector?: IUser
  additional_inspectors?: IUser[] | string[]
  country?: string
  project?: string

  region?: string
  due_date?: Date
  date_of_audit?: Date
  po_number?: string
  po_item?: string
  inspection_number?: string
  aircraft_register?: string
  yom?: Date
  dom?: Date

  creation_date?: Date | string
  creation_author?: IUser | string
}

export interface IAircraftInspectionHistory {
  _id: string
  aircraft: IAircraft
  inspections: IInspection[]
  last_inspection?: IInspection
}

export interface IReportFile {
  author: IUser
  date: Date
  file: string
  file_path?: string
  file_name: string
  file_size: number
  type: 'attachment' | 'export'
}

export const reportExportTypes = ['xlsx', 'docx'] as const

export type TNewReportExportType = typeof reportExportTypes[number]

export const reportExportStatuses = ['requested', 'generating', 'done', 'error'] as const

export type TNewReportExportStatus = typeof reportExportStatuses[number]

export interface INewReportExport {
  _id: string
  report?: string
  report_template?: string
  author?: IUser
  type: TNewReportExportType
  status: TNewReportExportStatus
  message?: string
  file_key?: string
  file_name?: string
  file_size?: number
  start_date: Date
  end_date?: Date
}

export interface IInspection {
  _id: string
  owner: ICompany
  aircraft: IAircraft
  general_info: IGeneralInfo
  status: TInspectionStatus
  report: IReport
  report_url?: string
  report_files?: IReportFile[]
  report_file_path?: string
}

export const complianceStatuses = [
  'Not Applicable',
  'Complied With',
  'Repetitive',
  'Not Complied With',
] as const
export type TComplianceStatus = typeof complianceStatuses[number]

export const adClasses = ['EAD', 'AD'] as const
export type TADClass = typeof adClasses[number]
export type TADItem = {
  ad_class?: TADClass | string
  authority?: string
  ad_number?: string // open_ad
  ata_chapter?: string
  asset_model?: string // should be autofilled
  description?: string
  applicable?: boolean
  // depends on applicable
  effective_date?: Date
  due_date?: string // doze n ad
  due_hours?: string
  due_cycles?: string
  // if not applicable
  applicability_comments?: string

  complied_with?: boolean
  // depends on complied_with
  date_complied_with?: Date
  compliance_record?: string
  // if not complied with
  compliance_comments?: string

  repetitive?: boolean
  // depends on repetitive
  next_due_date?: Date
  next_due_hours?: string
  next_due_cycles?: string

  // open_ad?: string
  // due_ad?: string
  // doze_n_ad?: string -> due_date
}

export const sbClasses = ['Mandatory', 'Optional'] as const
export type TSBClass = typeof sbClasses[number]

export const sbTypes = ['Airframe', 'Engine', 'Component'] as const
export type TSBType = typeof sbTypes[number]

export type TSBItem = {
  sb_class?: TSBClass
  type?: TSBType | string
  sb_number?: string // open_sb
  ata_chapter?: string
  asset_model?: string // should be autofilled
  description?: string
  applicable?: boolean
  // depends on applicable
  effective_date?: Date
  due_date?: Date // doze_dn_sb
  due_hours?: string
  due_cycles?: string
  // if not applicable
  applicability_comments?: string

  complied_with?: boolean
  // depends on complied_with
  date_complied_with?: Date
  compliance_record?: string
  // if not complied with
  compliance_comments?: string

  repetitive?: boolean
  // depends on repetitive
  next_due_date?: Date // due_sb
  next_due_hours?: string
  next_due_cycles?: string
  // open_sb?: string
  // due_sd?: string
  // doze_n_sd?: string
}

export type TSTCItem = {
  description?: string
  number?: number
  number_stc?: number
  install_date?: Date
  install_date_stc?: string
  description_stc?: string
}

export type TRepairInfoItem = {
  st_rep?: string
}

export type TIncidentOrAccidentItem = {
  uuid?: string
  description?: string
}

export type TComponentChangeItem = {
  uuid?: string
  description?: string
  group?: string
}

export type TComponentExtraInfo = 'pnacft' | 'snacft' | 'pncard' | 'sncard' | 'finding'

export interface IReportSubmittionComponent {
  [component_id: string]: {
    [parameter_id: string]: string
  }
}

export type TSNSNASubmission = {
  value: 'satisfactory' | 'not_satisfactory' | 'not_applicable'
  notes: string
}

export interface ITrackingItems {
  ad: TADItem[]
  sb: TSBItem[]
  stc: TSTCItem[]
  repair_info: TRepairInfoItem[]
  incidents_or_accidents: TIncidentOrAccidentItem[]
  component_changes: TComponentChangeItem[]
}

export type TAllTrackingItems = ITrackingItems[keyof ITrackingItems]

export type TSingleTrackingItem = {
  [K in keyof ITrackingItems]: ITrackingItems[K][number]
}[keyof ITrackingItems]
export interface ISubmissionValues {
  tracking_items: ITrackingItems
  inspection_items: { [index: string]: string | Date | number }
  component_items: IReportSubmittionComponent
  general_info: IGeneralInfo
  physical_items: {
    [index: string]: TSNSNASubmission | string | number
  }
  valuation_items: {
    [index: string]: string | number | Date
  }
}

export interface IReport {
  _id: string
  submitted?: boolean
  approved?: boolean
  locked_date?: boolean
  general_info: IGeneralInfo
  basic_items: IInspectionItem[]
  inspection_items: IInspectionItem[]
  component_items: IComponent[]
  component_parameter_groups: IComponentParameterGroup[]
  tracking_items: {
    ad: boolean
    sb: boolean
    stc: boolean
    repair_info: boolean
    incidents_or_accidents: boolean
    component_changes: boolean
  }
  submission_values: ISubmissionValues
  valuation_items_categories?: IValuationItemsCategory[]
  physical_items_categories?: IPhysicalItemCategory[]
  photo_categories: IPhotoCategory[]
  discrepancy_categories: IDiscrepancyCategory[]
  document_categories: string[]
  record_categories: {
    uuid: string
    name: string
    description?: string
    allowed_file_classes: IFileClass[]
  }[]
}

export interface IPhotoCategory {
  name: string
  uuid: string
  what_to_look_for: string
  image_key?: string
  description: string
  local_photo?: File

  main_photo: boolean
  data_plate: boolean
  part_one: boolean
  findings: boolean
  additional_images: boolean
}

export interface IDiscrepancyCategory {
  name: string
}

export const initialDiscrepancyCategory = (): IDiscrepancyCategory => ({
  name: '',
})

export type TDiscrepancyPropertyIdentifier = 'type' | 'location'
export type TPaintingIssuePropertyIdentifier = 'type' | 'location'

export interface IDiscrepancyProperty {
  _id?: string
  identifier: TDiscrepancyPropertyIdentifier
  value: string
}
export interface IPaintingIssueProperty {
  _id?: string
  identifier: TPaintingIssuePropertyIdentifier
  value: string
}

export const discrepancyClassifications = ['major', 'intermediate', 'minor', 'observation']

export interface IReportPhoto {
  _id?: string
  category: string
  section: TPhotoSection
  file_key?: string
  report?: string | IReport
  file_url?: string
  discrepancy_id?: string

  // Performance Helper
  upload_index?: string
  local_photo_url?: string
}

export const photoSections = [
  'main_photo',
  'discrepancy',
  'discrepancy_related',
  'painting_issue',
  'other_photo',
  'data_plate',
] as const

export type TPhotoSection = typeof photoSections[number]

export interface IReportDocument {
  _id?: string
  report: string | IReport
  category: string
  file_key: string
  name?: string
}

export type TReportExportMode = 'detailed' | 'lean'

export interface IReportExport {
  author: string
  mode: TReportExportMode
  date: Date
  file: string
  file_size?: number
}

export interface IRequestParams {
  [key: string]: string
}

export interface IReportTemplate {
  _id?: string
  customer: ICompany | null
  owner: ICompany | null
  file_name: string | null
  file_key: string | null
  upload_date: Date | string | null
  export_preferences?: {
    prefix?: string
    image_width?: number
    image_height?: number
  }
  updated_by?: IUser
  updated_at?: Date
  revision_number?: number
}

export const generateMockReportTemplate = () => ({
  customer: null,
  file_name: '',
  file_key: '',
  upload_date: null,
  prefix: '',
  image_width: 12,
  image_height: 9,
})

export const generateMockReportTemplateForm = (): IUploadReportTemplateForm => ({
  customer: null,
  files: [],
  prefix: '',
  image_width: 12,
  image_height: 9,
})

export type TSelectionToggle = 'select' | 'unselect'

export const reportDiscrepancyItemTypes = ['technical', 'physical'] as const

export type TReportDiscrepancyItemType = typeof reportDiscrepancyItemTypes[number]

export const reportDiscrepancyClassificationsValue = [
  'major',
  'intermediate',
  'minor',
  'observation',
] as const

export type TReportDiscrepancyClassification = typeof reportDiscrepancyClassificationsValue[number]

interface IDiscrepancyClassificationsLabel {
  value: TReportDiscrepancyClassification
  label: string
}

export const reportDiscrepancyClassifications: IDiscrepancyClassificationsLabel[] = [
  { value: 'major', label: 'Major' },
  { value: 'intermediate', label: 'Intermediate' },
  { value: 'minor', label: 'Minor' },
  { value: 'observation', label: 'Observation' },
]

export const reportDiscrepancyStatuses = ['open', 'closed'] as const

export type TReportDiscrepancyStatus = typeof reportDiscrepancyStatuses[number]

export interface IDiscrepancy {
  _id?: string
  report: string
  description?: string
  item_type?: TReportDiscrepancyItemType
  category?: string
  photo_id?: string
  location_photo_id?: string
  location?: string
  type?: string
  classification?: TReportDiscrepancyClassification
  observation?: string
  status?: TReportDiscrepancyStatus
  comments?: string
  corrective_action?: string
  operator_feedback?: string
  operator_feedback_id?: string
  creation_date?: Date
  deadline?: string
  modification_date?: Date
  status_last_operator_feedback?: string
  // Optional populated photo
  photo?: IReportPhoto
}

export interface IPaintingIssue {
  _id?: string
  report: string
  description?: string
  category?: string
  photo_id?: string
  location?: string
  type?: string
  classification?: TReportDiscrepancyClassification
  observation?: string
  status?: TReportDiscrepancyStatus
  comments?: string
  corrective_action?: string
  creation_date?: Date
  modification_date?: Date

  // Optional populated photo
  photo?: IReportPhoto
}

interface IInitialReportDiscrepancy {
  reportId: string
  item_type?: TReportDiscrepancyItemType
}

export const initialReportDiscrepancy = ({
  reportId,
  item_type,
}: IInitialReportDiscrepancy): IDiscrepancy => ({
  report: reportId,
  classification: 'minor',
  item_type: item_type || 'technical',
  description: '',
  comments: '',
  location: '',
  observation: '',
  photo_id: '',
  status: 'open',
  type: '',
  status_last_operator_feedback: '',
})

export interface IReportDiscrepancyPhoto extends IReportPhoto {
  discrepancy?: IDiscrepancy
}

export interface IReportPaintingIssuePhoto extends IReportPhoto {
  painting_issue?: IPaintingIssue
}

export interface IValuationItem {
  _id?: string
  description: string
  variable_name: string
  variable_datatype: Exclude<TVariableDatatype, 'yes/no'>
}

export const initialValuationItem = (): IValuationItem => ({
  description: '',
  variable_datatype: 'string',
  variable_name: '',
})

export type JWTDecoded<T> = {
  params: T
  iat: number
  exp: number
}

export interface ListInspectionPagination {
  inspections?: ListInspectionItem[]
  limit: number
  page: number
  total_pages: number
}

export interface ListInspectionItem {
  customer_name: string
  aircraft_serial_number: string
  aircraft_manufacture_date: Date
  aircraft_manufacturer_name: string
  aircraft_model_name: string
  aircraft_register: string
  aircraft_id: string
  inspection_company_name: string
  operator_name: string
  country: string
  due_date: Date
  date_of_audit?: Date
  status: TInspectionStatus
  project: string
  inspector_name: string
  inspection_id: string
  report_files: IReportFile[]
  inspection_number: string
  report_submitted: boolean
  can_edit_status: boolean
  checklist_id: string
  checklist_description: string
  checklist_scope: string
  created_by: string
  creation_date: Date
}

export interface IEvidence {
  uuid: string
  name: string
  size: number
  type: 'image' | 'file'
  key: string
  local_url?: string
  local_file?: File
}
export interface IOperatorFeedback {
  _id?: string
  discrepancy_id: string
  operator_comments: string
  inspector_observations: string
  last_answer_date: Date
  evidences: IEvidence[]
}

export const generateMockOperatorFeedback = (discrepancyId = ''): IOperatorFeedback => ({
  discrepancy_id: discrepancyId,
  operator_comments: '',
  inspector_observations: '',
  evidences: [],
  last_answer_date: new Date(),
})

export const invitationLevels = ['view_all', 'limited_view'] as const
export type TInvitationLevel = typeof invitationLevels[number]

export interface IInvitationLevelOption {
  value: TInvitationLevel
  label: string
}

export const invitationLevelOptions: IInvitationLevelOption[] = [
  {
    label: 'View all (include images, records and findings)',
    value: 'view_all',
  },

  {
    label: 'Limited view (no view on findings)',
    value: 'limited_view',
  },
]

export interface IInspectionInvitation {
  _id: string
  user: IUser
  level: TInvitationLevel
  allow_download_report: boolean
  allow_answer_findings: boolean
  inspection: string
  expiration_date: Date
  revoked: boolean
  created_by: IUser
  created_at: Date
}
export interface IInviteUserForm {
  user: IUser
  level: TInvitationLevel
  inspection: string
  expiration_date: Date
  allow_download_report: boolean
  allow_answer_findings: boolean
}

export interface ILoginFirstStepResponse {
  message: string
  cant_transmit_before: number
  mfa_access_token: string

  // Returned in login response if env `AUTH_RETURN_PIN_IN_LOGIN` was set to true in API.
  pin?: number
}

export interface IWorkspaceOption {
  id: string
  name: string
  profile: string
  active: boolean
  company_name: string
  profile_picture: string
  company_picture: string
}
export interface ICheckPINResponse {
  workspace_options: IWorkspaceOption[]
  select_workspace_token: string
  identity: {
    name: string
    email: string
  }
}

export type IChangeWorskpaceResponse = ICheckPINResponse

export interface ISupportForm {
  subject: string
  description: string
}

export const initialSupport = (user: IUser | null): ISupportForm => {
  const initial = {
    subject: '',
    description: '',
  }

  if (user) return initial

  return { ...initial, ...{ name: '', email: '', phone_number: '' } }
}

export type Creatable<T> = Omit<T, '_id'>
export const ResourceScopes = ['global', 'company'] as const
export type TResourceScope = typeof ResourceScopes[number]

export const getResourceScopeLabel = (scope: TResourceScope) => capitalize(scope)

export interface IResourceScope {
  owner?: ICompany
  resource_scope: TResourceScope
}

export interface ITag {
  _id: string
  name: string
}
export interface IFileProperties {
  _id: string
  name: string
  variable_type:
    | 'string'
    | 'email'
    | 'date'
    | 'year'
    | 'number'
    | 'decimal'
    | 's/ns/na'
    | 'g/o/b'
    | 'yes/no'
  variable_name: string
}

export interface IFileClass {
  _id: string
  description: string
  asset_type: IAssetType
  tags: ITag[]
  tag_order_mode: 'name' | 'position'
  properties: IFileProperties[]
  variable_name_property: string[]
  instructions_to_zeh: string
}

export const initialFileClass = (assetType?: IAssetType): Partial<IFileClass> => {
  return {
    description: '',
    asset_type: assetType ?? {
      _id: '',
      name: '',
    },
  }
}

export type TDatatypeOption = typeof datatypeOptions[number]

export interface IRecordFile {
  _id: string
  created_at?: Date
  report?: IReport
  record_category_id?: string
  file_class?: IFileClass
  file_class_properties?: any
  file_name: string
  file_key: string
  tags?: string[]
  additional_comments?: string
  components_affected?: {
    pn: string
    sn: string
  }[]
  _metadata?: {
    ai_comments?: string
  }
}

export enum SBType {
  OPTIONAL = 'optional',
  RECOMMENDED = 'recommended',
  ALERT = 'alert',
  MANDATORY = 'mandatory',
}

export enum SBStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  SUPERSEDED = 'superseded',
  CANCELLED = 'cancelled',
}

export enum SBAuthority {
  ANAC = 'anac',
  FAA = 'faa',
  EASA = 'easa',
  CA = 'ca',
}

export interface ISB {
  _id?: string
  identifier: string
  description: string
  issuer: SBAuthority
  type: SBType
  status: SBStatus
  issue_date: string
  effective_date: string
  applicable_asset_models: any[]
  applicable_assets: any[]
  applicable_components: any[]
  created_at?: string
  updated_at?: string
}

export type ICreateSB = Omit<ISB, '_id' | 'created_at' | 'updated_at'>

export type IUpdateSB = Partial<ICreateSB>

export interface IImportSB {
  files: File[]
  authority: SBAuthority
}

export type PaginatedSB = IPaginated<ISB>

export const sbTypeLabels = [
  { label: 'Optional', value: SBType.OPTIONAL },
  { label: 'Recommended', value: SBType.RECOMMENDED },
  { label: 'Alert', value: SBType.ALERT },
  { label: 'Mandatory', value: SBType.MANDATORY },
]

export const sbStatusLabels = [
  { label: 'Draft', value: SBStatus.DRAFT },
  { label: 'Active', value: SBStatus.ACTIVE },
  { label: 'Superseded', value: SBStatus.SUPERSEDED },
  { label: 'Cancelled', value: SBStatus.CANCELLED },
]

export const sbAuthorityLabels = [
  { label: 'ANAC', value: SBAuthority.ANAC },
  { label: 'FAA', value: SBAuthority.FAA },
  { label: 'EASA', value: SBAuthority.EASA },
  { label: 'CA', value: SBAuthority.CA },
]

export const initialSB = (): ISB => ({
  identifier: '',
  description: '',
  issuer: SBAuthority.ANAC,
  type: SBType.OPTIONAL,
  status: SBStatus.DRAFT,
  issue_date: '',
  effective_date: '',
  applicable_asset_models: [],
  applicable_assets: [],
  applicable_components: [],
})
export interface ITCDS {
  _id?: string
  identifier: string
  description?: string
  authority?: TCDSAuthority
  revision_date?: Date
  file_url?: string
  created_at?: string
  updated_at?: string
}

export type TCreateTCDS = Omit<ITCDS, '_id' | 'updated_at' | 'created_at'>

export type TUpdateTCDS = Partial<TCreateTCDS>

export interface PaginatedTCDS {
  docs: ITCDS[]
  metadata: {
    total: number
    limit: number
    offset: number
  }
}

export enum TCDSAuthority {
  ANAC = 'anac',
  FAA = 'faa',
  EASA = 'easa',
  CA = 'ca',
}

export const tcdsAuthorityLabels = [
  { value: TCDSAuthority.ANAC, label: 'ANAC' },
  { value: TCDSAuthority.FAA, label: 'FAA' },
  { value: TCDSAuthority.EASA, label: 'EASA' },
  { value: TCDSAuthority.CA, label: 'CA' },
]

export const initialTCDS = (): ITCDS => ({
  identifier: '',
  description: '',
  authority: tcdsAuthorityLabels?.at(0)?.value,
  revision_date: undefined,
  file_url: '',
})
export interface IAD {
  _id?: string
  number: string
  description: string
  authority: string
  emission_date: string
  effective_date: string
  scope: string
  applicable_asset_models: IAircraftModel[] | string[]
}

export const initialAD = (): IAD => ({
  number: '',
  description: '',
  authority: '',
  emission_date: '',
  effective_date: '',
  scope: '',
  applicable_asset_models: [],
})

export type TAuthorityLabelsOption = {
  label: string
  value: string
}

export const authorityLabels: TAuthorityLabelsOption[] = [
  { label: 'ANAC', value: 'anac' },
  { label: 'EASA', value: 'easa' },
  { label: 'FAA', value: 'faa' },
  // { label: 'CA', value: 'ca' },
]
