import { AuthenticationResult, EventType, PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { ReactNode } from 'react'
import { msalConfig } from '../../msal.config'

const msalInstance = new PublicClientApplication(msalConfig)

interface IAuthProviderProps {
  children: ReactNode
}

export const AuthMSProvider = ({ children }: IAuthProviderProps) => {
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
  }

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const authenticationResult = event.payload as AuthenticationResult
      const account = authenticationResult.account

      if (account) {
        msalInstance.setActiveAccount(account)
        console.log('Active MS account:', account)
      }
    }
  })

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>
}

export function useAuthMSProvider() {
  return { AuthMSProvider }
}
